import { Layout } from "antd";
import { FormattedMessage } from "react-intl";
const { Footer } = Layout;

const GeneralFooter = () => {
  return (
    <Footer className="flex justify-center text-white bg-gray-900 font-primary">
      <FormattedMessage id="LOYALTY_TM" />
    </Footer>
  );
};

export default GeneralFooter;
