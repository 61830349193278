import { Link, useLocation } from "react-router-dom";
import { Layout, Menu, MenuProps } from "antd";
import smallIcon from "../../../assets/images/aggregator_logo_green.png";
import { useIntl } from "react-intl";

import {
  DashboardOutlined,
  ShopOutlined,
  UserOutlined,
  CreditCardOutlined,
  BookOutlined,
  TeamOutlined,
  ToolOutlined,
  ProductOutlined,
  BankOutlined,
  LineChartOutlined,
  GiftOutlined,
  ClusterOutlined,
  IdcardOutlined
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_INTERNAL_PROFILE } from "./SideMenu.gql";
import { authClient } from "../../../apollo-clients";
import React from "react";
import { SET_CURRENT_USER, useAuth } from "../../../hooks/auth/AuthContext";

const { Sider } = Layout;
const { SubMenu } = Menu;

interface Props {
  collapsed: boolean;
  setCollapsed: any;
}

type MenuItem = Required<MenuProps>["items"][number];
const SideMenu = ({ collapsed, setCollapsed }: Props) => {
  const { innerWidth: width } = window;
  const intl = useIntl();
  const location = useLocation();
  const [sideNavs, setSideNavs] = useState<any[]>();
  const [getInternalProfile, { data }] = useLazyQuery(GET_INTERNAL_PROFILE, {
    client: authClient,
    fetchPolicy: "cache-and-network",
  });
  const { authState, authDispatch } = useAuth();
  const [selectedKey, setSelectedKey] = useState<string>(location.pathname);
  const [subMenuKey, setSubMenuKey] = useState<string>();

  useEffect(() => {
    if (authState.isLogin) {
      getInternalProfile();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.isLogin]);

  useEffect(() => {
    const selectedKeys = location.pathname.split('/');
    setSelectedKey(location.pathname);
    const expandedSubMenu = selectedKeys.length > 2 ? `/${selectedKeys[1]}` : undefined;
    setSubMenuKey(expandedSubMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (data && data.internalProfile) {
      const intProfile = data.internalProfile;
      authDispatch({
        type: SET_CURRENT_USER,
        payload: {
          userProfile: {
            id: intProfile.id,
            name: intProfile.name,
            userName: intProfile.userName,
            roleName: intProfile.roleName,
            canManageSystemUser: intProfile.canManageSystemUser,
            branches: intProfile.branches,
            userType: intProfile.userType,
          },
          sideNav: intProfile.sideNavigation,
        },
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //map child to parents to render the side nav
  useEffect(() => {
    if (authState.sideNav && authState.sideNav.length > 0) {
      const sortedData = authState.sideNav;
      const parentSideNavs: any[] = sortedData.filter((sn) => !sn.parentId);
      const nestedSideNav: any[] = parentSideNavs.map((sn) => {
        const parentSideNav = { ...sn };
        parentSideNav.child = sortedData.filter((c) => c.parentId === sn.id);
        return parentSideNav;
      });
      setSideNavs(nestedSideNav);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.sideNav]);

  const getIcon = (iconName: string) => {
    switch (iconName) {
      case "UserOutlined":
        return <UserOutlined />;
      case "ShopOutlined":
        return <ShopOutlined />;
      case "CreditCardOutlined":
        return <CreditCardOutlined />;
      case "BookOutlined":
        return <BookOutlined />;
      case "DashboardOutlined":
        return <DashboardOutlined />;
      case "TeamOutlined":
        return <TeamOutlined />;
      case "ToolOutlined":
        return <ToolOutlined />;
      case "ProductOutlined":
        return <ProductOutlined />;
      case "BankOutlined":
        return <BankOutlined />;
      case "LineChartOutlined":
        return <LineChartOutlined />;
      case "GiftOutlined":
        return <GiftOutlined />;
      case "ClusterOutlined":
        return <ClusterOutlined />
      case "IdcardOutlined":
        return <IdcardOutlined />
    }
  };

  function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const items: any[] =
    sideNavs?.map((s) => {
      if (s.child && s.child.length > 0) {
        return getItem(
          intl.formatMessage({ id: s.name }),
          s.id,
          getIcon(s.icon),
          s.child.map((child: any) => {
            return getItem(
              <Link to={child.route}>
                {intl.formatMessage({ id: child.name })}
              </Link>,
              intl.formatMessage({ id: child.name })
            );
          })
        );
      }
      return getItem(
        <Link to={s.route}>{intl.formatMessage({ id: s.name })}</Link>,
        s.route,
        getIcon(s.icon)
      );
    }) || [];

  const onOpenChange = (e: any) => {
    if (!e || (e && e.length === 0)) {
      setSubMenuKey(undefined);
    } else if (e && e.length > 1) {
      setSubMenuKey(e[1]);
    } else if (e && e.length > 0) {
      setSubMenuKey(e[0]);
    }
  };

  const renderMenu = () => {
    return (
      <>
        <Menu
          selectedKeys={[selectedKey ? selectedKey : '/']}
          openKeys={(subMenuKey) ? [subMenuKey] : undefined}
          onOpenChange={onOpenChange}
          defaultSelectedKeys={["Dashboard"]}
          mode={"inline"}
          theme="dark"
        >
          {
            sideNavs?.map((sideNav) => {
              return (
                sideNav.child.length > 0 ?
                  <SubMenu key={sideNav.route} icon={getIcon(sideNav.icon)}
                    title={<>{intl.formatMessage({ id: sideNav.name })}</>} >
                    {renderChildMenu(sideNav.child)}
                  </SubMenu >
                  :
                  <Menu.Item key={sideNav.route} icon={getIcon(sideNav.icon)} onClick={width <= 768 ? () => { setCollapsed(true) } : () => { }} >
                    <Link to={sideNav.route}>{intl.formatMessage({ id: sideNav.name })}</Link>
                  </Menu.Item>
              );
            })
          }
        </Menu>
      </>
    );
  };

  const renderChildMenu = (childMenu: any[]) => {
    return childMenu.map((child) => {
      return (<Menu.Item key={child.route} onClick={width <= 768 ? () => { setCollapsed(true) } : () => { }}>
        <Link to={child.route}>{intl.formatMessage({ id: child.name })}</Link>
      </Menu.Item>);
    })
  }

  return (
    <Sider
      className="overflow-scroll"
      width={window.innerWidth < 768 ? window.innerWidth : 256}
      trigger={null}
      collapsible
      collapsed={collapsed}
      collapsedWidth={window.innerWidth < 768 ? 0 : 80}
    >
      <div className="relative flex items-center justify-center w-full max-h-7 h-7">
        {/* <img
          src={smallIcon}
          alt="loyalty"
          className={"max-w-[96px] w-full"}
        /> */}
      </div>
      {renderMenu()}
    </Sider>
  );
};

export default SideMenu;
