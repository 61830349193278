import { ConfigProvider, Layout, Spin, notification } from "antd";
import { routes, RouteInfo } from "./routes";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { Suspense, useEffect, useRef, useState } from "react";
import SideMenu from "./shared/components/side-menu/SideMenu";
import Header from "./shared/components/header/GeneralHeader";
import Footer from "./shared/components/footer/GeneralFooter";
import { IntlProvider } from "react-intl";
import { CLEAR_NOTIFICATION, useLayout } from "./hooks/layout/LayoutContext";
import messages_en from "./assets/i18n/en.json";
import message_zh_cn from "./assets/i18n/zh_cn.json";
import {
  CLEAR_CURRENT_USER,
  SET_LOGIN_STATUS,
  useAuth,
} from "./hooks/auth/AuthContext";
import { useMutation } from "@apollo/client";
import { authClient, client } from "./apollo-clients";
import { LOGOUT } from "./shared/graphql/auth/auth.gql";
import Cookies from "universal-cookie";
import { useCookies } from "react-cookie";
import './App.css'

const App = () => {
  const { Content } = Layout;
  const { innerWidth: width } = window;
  const [collapsed, setCollapsed] = useState(width > 768 ? false : true);
  const { layoutState, layoutDispatch } = useLayout();
  const { authState, authDispatch } = useAuth();
  const [cookies] = useCookies();
  const cookiesUni = new Cookies();
  const [signOut, { data, error }] = useMutation(LOGOUT, {
    client: authClient,
    errorPolicy: "ignore",
    onError: (err) => signOutCleanUp(),
    //swallow error and proceed to clear profile
  });
  const navigate = useNavigate();
  const verifyingToken = useRef<boolean>(false);

  const aToken: any = process.env.REACT_APP_ACCESS_TOKEN || "a-at";
  const rToken: any = process.env.REACT_APP_REFRESH_TOKEN || "a-rt";

  const toggleSideNav = () => {
    setCollapsed(!collapsed);
  };

  const messages: any = {
    en: messages_en,
    zh_cn: message_zh_cn,
  };

  const logout = () => {
    signOut();
    signOutCleanUp();
  };

  useEffect(() => {
    if (data || error) {
      client.resetStore();
      authClient.resetStore();
    } // eslint-disable-line react-hooks/exhaustive-deps
  }, [data, error]);

  useEffect(() => {
    const token = cookies[rToken];
    authDispatch({ type: SET_LOGIN_STATUS, payload: token ? true : false });
    //verifyingToken.current = true;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!cookiesUni.get(rToken)) {
      signOutCleanUp();
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookiesUni.get(rToken)])

  const signOutCleanUp = () => {
    const isProd = process.env.NODE_ENV === "production";

    cookiesUni.remove(aToken, {
      path: "/",
      domain: isProd ? window.location.hostname : "localhost",
    });
    cookiesUni.remove(rToken, {
      path: "/",
      domain: isProd ? window.location.hostname : "localhost",
    });
    authDispatch({ type: CLEAR_CURRENT_USER });
    authDispatch({ type: SET_LOGIN_STATUS, payload: false });
    navigate("/login", { replace: true });
  };

  const protectedRoutesAccessCheck = (route: RouteInfo) => {
    if (
      route.protected &&
      !authState.isLogin
      //verifyingToken.current === true
    ) {
      return <Navigate to="/login" replace />;
    } else {
      return (
        <Suspense fallback={<Spin />}>
          <route.component routes={route.routes} />
        </Suspense>
      );
    }
  };

  useEffect(() => {
    if (layoutState.showNotification && layoutState.showNotification.message) {
      openNotificationWithIcon();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutState.showNotification]);

  const openNotificationWithIcon = () => {
    notification[layoutState?.showNotification?.type]({
      message: layoutState?.showNotification.message,
      description: layoutState?.showNotification?.description,
    });
    layoutDispatch({ type: CLEAR_NOTIFICATION });
  };

  return (
    <Spin tip="Loading..." spinning={layoutState.showLoading}>
      <IntlProvider
        locale={layoutState.locale}
        messages={messages[layoutState.locale]}
      >
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "green",
            },
          }}
        >

          <Layout className="h-screen bg-gray-900 w-full">
            {authState.isLogin && <SideMenu setCollapsed={setCollapsed} collapsed={collapsed} />}
            <Layout className="bg-gray-900">
              {authState.isLogin ? (width < 768 && !collapsed) ? <></> : <Header
                collapsed={collapsed}
                toggleSideNav={toggleSideNav}
                logout={logout}
              /> : <></>
              }
              {(width < 768 && !collapsed) ? <></> :
                <Content
                  id="main-layout"
                  className="p-4 mx-4 my-3 overflow-y-auto bg-gray-900 font-primary"
                >
                  <Routes>
                    {routes.map((route: RouteInfo, i) => {
                      return (
                        <Route
                          key={i}
                          path={route.path}
                          element={protectedRoutesAccessCheck(route)}
                        />
                      );
                    })}
                  </Routes>
                </Content>
              }
              {(authState.isLogin) ? (width < 768 && !collapsed) ? <></> : <Footer /> : <></>}
            </Layout>
          </Layout>

        </ConfigProvider>
      </IntlProvider>
    </Spin>
  );
};

export default App;
