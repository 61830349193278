import React, { createContext, useContext, useReducer } from "react";

interface SideNav {
  id: string;
  name: string;
  icon: string;
  order: number;
  route: string;
  parentId: string;
  roleType: string;
}

interface AuthState {
  isLogin: boolean;
  loginStatus: any;
  indicatorList: any[];
  userProfile: null | {
    id: string;
    name?: string;
    userName?: string;
    role: any;
    userType: any
  };
  sideNav: SideNav[];
}

const INITIAL_STATE: AuthState = {
  isLogin: false,
  loginStatus: null,
  userProfile: null,
  indicatorList: [],
  sideNav: [],
};

export const SET_LOGIN_STATUS = "[AUTH] SET_LOGIN_STATUS";
export const SET_LOGIN_ERROR = "[AUTH] SET_LOGIN_ERROR";
export const SET_CURRENT_USER = "[AUTH] SET_CURRENT_USER";
export const CLEAR_CURRENT_USER = "[AUTH] CLEAR_CURRENT_USER";
export const UPDATE_INDICATOR = "[AUTH]UPDATE_INDICATOR";

const reducer = (
  state: AuthState,
  action: { type: string; payload: any }
): AuthState => {
  const { type, payload } = action;
  switch (type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        userProfile: payload.userProfile,
        sideNav: payload.sideNav.map((s: any) => {
          return {
            ...s,
          };
        }),
      };
    case SET_LOGIN_STATUS:
      return { ...state, isLogin: payload };
    case UPDATE_INDICATOR:
      return { ...state, indicatorList: payload };
    case CLEAR_CURRENT_USER:
      return INITIAL_STATE;
    default:
      return state;
  }
};

const authContext = createContext<{
  authState: AuthState;
  authDispatch: React.Dispatch<any>;
}>({ authState: INITIAL_STATE, authDispatch: () => null });

export const AuthProvider = ({ children }: any) => {
  const [authState, authDispatch] = useReducer(reducer, INITIAL_STATE);
  return (
    <authContext.Provider value={{ authState, authDispatch }}>
      {children}
    </authContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(authContext);
};
